// src/components/UserRedirect.js

//this component redirects the user for login if not already logged in.

import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

function UserRedirect() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("accessToken");

      // If no token is found, redirect to login, unless on the subscribe page
      if (!token && location.pathname !== "/subscribe") {
        navigate("/login");
        return;
      }

      try {
        const config = {
          method: "get",
          url: "https://ktapis.ktgamez.com/irancell/api/v1/users/me",
          headers: {
            Authorization: `Bearer ${token}`, // Pass the token in the header
          },
        };

        const response = await axios(config);

        // If user is registered, do nothing, just proceed
        if (response.data && response.data.body) {
          console.log("User is registered:", response.data);
        } else {
          // If response data doesn't exist, redirect to login
          navigate("/login");
        }
      } catch (error) {
        // Handle the error: Invalid token, expired session, etc.
        console.error("Error fetching user data:", error);
        localStorage.removeItem("accessToken");
        navigate("/login");
      }
    };

    // Only fetch user data if not on the login or subscribe page
    if (location.pathname !== "/login" && location.pathname !== "/subscribe") {
      fetchUserData();
    }
  }, [navigate, location]);

  return null;
}

export default UserRedirect;
