// src/App.js
import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { BrowserRouter as Router } from 'react-router-dom';
import axios from "axios";

import Login from "./components/Login";
import UserRedirect from "./components/UserRedirect"; // New component for user authentication check
import Subscribe from "./components/Subscribe"; // New component for subscription
import GameList from "./components/GameList";
import GameDetails from "./components/GameDetails";
import Navbar from "./components/Navbar";
import Leaderboard from "./components/Leaderboard"; // Import Leaderboard component
import LeaderboardGame from "./components/LeaderboardGame";
import GamePage from "./components/GamePage";
import GenreSidebar from "./components/GenreSidebar"; // Import Sidebar
import ScrollToTopButton from "./components/ScrollToTopButton"; // Import ScrollToTopButton
import Footer from "./components/Footer"; // Import Footer
import "./App.css";

function App() {
  const [selectedGenre, setSelectedGenre] = useState("All");
  const [points, setPoints] = useState(0);
  const location = useLocation(); // Get the current location

  const getUserDetails = async () => {
    try {
      const response = await axios.get('https://ktapis.ktgamez.com/irancell/api/v1/users/me', {
        headers: { 
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json'
        },
        maxBodyLength: Infinity,
      });
      
      setPoints(response?.data?.body?.points || 0);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getUserDetails(); // Fetch user details whenever the location changes
  }, [location]);

  return (
    <div className="App">
      <header>
        <Navbar setSelectedGenre={setSelectedGenre} points={points} />
      </header>
      <GenreSidebar setSelectedGenre={setSelectedGenre} /> {/* Include Sidebar */}
      <main className="flex-grow-1">
        <UserRedirect /> {/* UserRedirect component will be responsible for checking user data */}
        <Routes>
          <Route path="/" element={<GameList selectedGenre={selectedGenre} />} />
          <Route path="/game/:id" element={<GameDetails />} />
          <Route path="/leaderboard" element={<Leaderboard />} /> 
          <Route path="/leaderboard-game/:id" element={<GamePage />} /> 
          <Route path="/login" element={<Login />} />
          <Route path="/subscribe" element={<Subscribe />} /> {/* New route for the game */}
        </Routes>
      </main>
      <Footer /> {/* Include Footer */}
      {/* <ScrollToTopButton /> */}
    </div>
  );
}

// Wrapped App with Router component


function WrappedApp() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default WrappedApp;
