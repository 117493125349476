// src/components/Login.js

// this is login page.

import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Login() {
  const [mobileNumber, setMobileNumber] = useState(""); // State to hold the mobile number
  const [isButtonDisabled, setIsButtonDisabled] = useState(true); // State to manage button disabled/enabled state
  const [error, setError] = useState(null); // State to manage error messages
  const navigate = useNavigate();

  // Handler to update mobile number and validate its length
  const handleMobileChange = (e) => {
    const value = e.target.value;

    // Only allow digits
    const validNumber = value.replace(/\D/g, "");
    setMobileNumber(validNumber);

    // Check if the number is exactly 12 digits
    if (validNumber.length === 12) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  };

  // Handler for the login button click
  const handleLogin = async () => {
    try {
      // Prepare the API request
      const config = {
        method: "post",
        url: "https://ktapis.ktgamez.com/irancell/api/v1/users/login",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          mobile: mobileNumber, // Use the state that holds the user's mobile number
        }),
      };

      // Make the API call to login the user
      const response = await axios(config);

      // Debugging: Check the API response structure
      console.log("API Response:", response.data);

      // Check if the login was successful and an access token is returned
      if (
        response.data &&
        response.data.body &&
        response.data.body.accessToken
      ) {
        // Save the accessToken in localStorage
        localStorage.setItem("accessToken", response.data.body.accessToken);

        // Navigate to the home page or another protected page
        navigate("/");
      } else {
        // Check if user is not registered and redirect to subscribe
        if (response.data && response.data.status === "User Not Registered") {
          // Redirect to the subscription page if the user is not registered
          navigate("/subscribe");
        } else {
          // Generic error message
          setError(
            "Login failed. Please check your mobile number and try again."
          );
        }
      }
    } catch (err) {
      console.error("Login Error:", err);
      setError("User does not exist. Please subscribe to enjoy all the games.");
    }
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title text-center">Login</h2>
              <form>
                <div className="form-group mb-3">
                  <label htmlFor="mobileNumber">Mobile Number</label>
                  <input
                    type="text"
                    id="mobileNumber"
                    name="mobileNumber"
                    value={mobileNumber}
                    onChange={handleMobileChange}
                    placeholder="Enter 12 digits mobile number e.g: 981111111111"
                    maxLength={12}
                    className="form-control"
                  />
                </div>
                <button
                  type="button"
                  className="btn btn-primary w-100"
                  onClick={handleLogin}
                  disabled={isButtonDisabled}
                >
                  Login
                </button>
              </form>
              {error && <div className="alert alert-danger mt-3">{error}</div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
