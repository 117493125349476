// src/components/LeaderboardGame.js

// This code displays the Leaderboard game.

// src/components/LeaderboardGame.js

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

function LeaderboardGame() {
  const [leaderboardGame, setLeaderboardGame] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLeaderboardGame = async () => {
      try {
        const token = localStorage.getItem("accessToken");

        if (!token) {
          setError("User not logged in.");
          return;
        }

        // Fetch Leaderboard Game from the API
        const leaderboardConfig = {
          method: "get",
          url: "https://ktapis.ktgamez.com/irancell/api/v1/users/leaderboard/games",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const leaderboardResponse = await axios(leaderboardConfig);
        if (leaderboardResponse.data.status === "Success") {
          setLeaderboardGame(leaderboardResponse.data.body[0]);
        }
      } catch (err) {
        console.error("Error fetching leaderboard game data:", err);
        setError("Failed to load leaderboard game.");
      }
    };

    fetchLeaderboardGame();
  }, []);

  if (error) {
    return <div className="alert alert-danger">{error}</div>;
  }

  if (!leaderboardGame) {
    return <div>Loading Leaderboard Game...</div>;
  }

  return (
    <div className="container">
      <h2 className="text-center text-light">
        <b>Leaderboard Game</b>
      </h2>
      <div className="row justify-content-center">
        <div className="col-md-5">
          <div className="card">
            <Link
              to={`/leaderboard-game/${leaderboardGame.id}`} // Redirect to gameplay screen
              className="game-name"
            >
              <img
                src={leaderboardGame.game_cover_url}
                className="card-img-top"
                alt={leaderboardGame.game_name}
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeaderboardGame;
