// src/components/GameList.js
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import LeaderboardGame from "./LeaderboardGame";

function GameList({ selectedGenre }) {
  const [games, setGames] = useState([]); // State to hold the fetched games
  const [displayCount, setDisplayCount] = useState({});
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  const INITIAL_DISPLAY_COUNT = 8; // Number of games to display initially

  // Function to fetch games from the API
  const fetchGames = async () => {
    const token = localStorage.getItem("accessToken"); // Retrieve token from localStorage

    if (!token) {
      setError("User not authenticated. Please log in.");
      setLoading(false);
      return;
    }

    try {
      const config = {
        method: "get",
        url: "https://ktapis.ktgamez.com/irancell/api/v1/users/games/all",
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      };

      const response = await axios(config);

      // Set the fetched games to the state
      setGames(response.data.body);
      setLoading(false); // Turn off loading state
    } catch (err) {
      setError("Failed to fetch games. Please try again later.");
      setLoading(false);
    }
  };

  // Call the API when the component mounts
  useEffect(() => {
    fetchGames();
  }, []);

  // Function to group games by genre
  const groupGamesByGenre = (games) => {
    return games.reduce((acc, game) => {
      if (!acc[game.genre_name]) {
        acc[game.genre_name] = [];
      }
      acc[game.genre_name].push(game);
      return acc;
    }, {});
  };

  const filteredGames =
    selectedGenre === "All"
      ? groupGamesByGenre(games)
      : {
          [selectedGenre]: games.filter(
            (game) => game.genre_name === selectedGenre
          ),
        };

  const handleLoadMore = (genre) => {
    setDisplayCount((prevDisplayCount) => ({
      ...prevDisplayCount,
      [genre]:
        (prevDisplayCount[genre] || INITIAL_DISPLAY_COUNT) +
        INITIAL_DISPLAY_COUNT,
    }));
  };

  if (loading) {
    return <div>Loading games...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="container mt-4">
      {selectedGenre === "All" && <LeaderboardGame />}
      <div className="text-center mt-5 mb-5">
        <Link to="/leaderboard" className="btn btn-danger ">
          <b>View Leaderboard</b>
        </Link>
      </div>
      {Object.keys(filteredGames).map((genre) => {
        const displayLimit = displayCount[genre] || INITIAL_DISPLAY_COUNT;
        const gamesToShow = filteredGames[genre].slice(0, displayLimit);
        const hasMoreGames = displayLimit < filteredGames[genre].length;

        return (
          <div key={genre} className="mb-4">
            <h2>
              <b>{genre}</b>
            </h2>
            <div className="row">
              {gamesToShow.map((game) => (
                <div
                  key={game.id}
                  className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4"
                >
                  <div className="card">
                    <Link to={`/game/${game.id}`} className="game-name">
                      <img
                        src={game.game_cover_url}
                        className="card-img-top"
                        alt={game.game_name}
                      />
                    </Link>
                  </div>
                </div>
              ))}
            </div>
            {hasMoreGames && (
              <div className="text-center mt-4">
                <button
                  className="btn btn-danger mb-4"
                  onClick={() => handleLoadMore(genre)}
                >
                  <b>Load More</b>
                </button>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default GameList;
