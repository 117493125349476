// src/components/GameDetails.js
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

function GameDetails() {
  const { id } = useParams(); // Get the game ID from the URL
  const [game, setGame] = useState(null); // State to hold the fetched game data
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  // Function to fetch the specific game details
  const fetchGameDetails = async () => {
    const token = localStorage.getItem("accessToken"); // Retrieve the token from localStorage

    if (!token) {
      setError("User not authenticated. Please log in.");
      setLoading(false);
      return;
    }

    try {
      const config = {
        method: "get",
        url: `https://ktapis.ktgamez.com/irancell/api/v1/users/games/all`, // API endpoint
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the header
        },
      };

      const response = await axios(config);

      // Find the specific game based on the ID from the URL
      const foundGame = response.data.body.find(
        (game) => game.id === parseInt(id)
      );

      if (foundGame) {
        setGame(foundGame); // Set the game details in the state
      } else {
        setError("Game not found.");
      }

      setLoading(false); // Turn off loading state
    } catch (err) {
      setError("Failed to fetch game details. Please try again later.");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGameDetails(); // Fetch the game details when the component mounts
  }, [id]); // Run this effect when the ID changes

  if (loading) {
    return <div>Loading game details...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!game) {
    return <div>Game not found.</div>;
  }

  return (
    <div className="container mt-4">
      <div className="row justify-content-center">
        <div className="col-12">
          <iframe
            src={game.game_play_url}
            title={game.game_name}
            allowfullscreen="true" 
            webkitallowfullscreen="true" 
            mozallowfullscreen="true" 
            msallowfullscreen="true" 
            style={{ width: "100%", height:"600px", border: "none" }}
            className="play-area" 
            scrolling="no" 
            marginheight="0" 
            marginwidth="0"
            frameborder="0"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default GameDetails;
