import React, { useState, useEffect } from "react";
import axios from "axios";
import { maskNumber } from "../Helpers/maskNumber";

function Leaderboard() {
  
  const [leaderboard, setLeaderboard] = useState([]);

  useEffect(() => {

    const fetchLeaderboard = async () => {

      try {
        
        const config = {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json'
          },
          maxBodyLength: Infinity
        };
    
        const response = await axios.post(
          'https://ktapis.ktgamez.com/irancell/api/v1/users/leaderboard',
          {},
          config
        );

        setLeaderboard(response?.data?.body || []);

      } catch (error) {
        
      }

    }

    fetchLeaderboard();

  }, []);

  return (
    <div className="main-content">
      <div className="container mt-4" style={{height: '75%', overflowY: 'auto'}}>
        <h2>Leaderboard</h2>
        <table className="table table-dark table-striped mt-3">
          <thead>
            <tr>
              <th>Rank</th>
              <th>Mobile Number</th>
              <th>Score</th>
            </tr>
          </thead>
          <tbody>
            {leaderboard?.map((user, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{maskNumber(user?.mobile) ?? "---"}</td>
                <td>{user?.points}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Leaderboard;
