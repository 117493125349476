import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

function GamePage() {
  const { id } = useParams(); // This is the game ID
  const [userId, setUserId] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const apiUrl = "https://ktapis.ktgamez.com/irancell/api/v1/users/";
  const [leaderboardGame, setLeaderboardGame] = useState({});

  useEffect(() => {
    
    const getLeaderboardGames  = async () => {
    
      try {
        const config = {
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          },
          maxBodyLength: Infinity,
        };
    
        const response = await axios.get(`https://ktapis.ktgamez.com/irancell/api/v1/users/leaderboard/games`, config);
        setLeaderboardGame(response?.data?.body[0]);
    
      } catch (error) {
       
      }
    }

    getLeaderboardGames();

  }, [id]);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");

    // Fetch the user data (userid)
    const fetchUserData = async () => {
      try {
        const config = {
          method: "get",
          url: "https://ktapis.ktgamez.com/irancell/api/v1/users/me",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios(config);

        if (response.data && response.data.status === "Success") {
          setUserId(response.data.body.id); // Store the user ID
        }

        setAccessToken(token); // Store the access token
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const gameFrame = document.getElementById("gameFrame");

    function sendMessageToGame(message) {
      gameFrame.contentWindow.postMessage(message, "*");
    }

    // Listen for messages from the game
    function handleMessage(event) {
      if (event.origin !== "https://cdn.girogamez.com") {
        console.error("Origin not allowed");
        return;
      }

      // When the game requests credentials, send them
      if (event.data.code === "requirecredentials") {
        sendMessageToGame({
          userid: userId,
          gameid: id, // Use the dynamic game ID from URL
          accessToken: accessToken,
          apiUrl: apiUrl,
        });
      }
    }

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [id, userId, accessToken, apiUrl]);

  return (
    <div className="container mt-5">
      <iframe
        id="gameFrame"
        src={`${leaderboardGame?.game_play_url}`} // This URL will change based on your game
        title="Leaderboard Game"
        frameborder="0"
        allowfullscreen="true" 
        webkitallowfullscreen="true" 
        mozallowfullscreen="true" 
        msallowfullscreen="true" 
        style={{ width: "100%", height:"600px", border: "none" }}
        className="play-area" 
        scrolling="no" 
        marginheight="0" 
        marginwidth="0"
      ></iframe>
    </div>
  );
}

export default GamePage;
