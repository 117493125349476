// this is subscribe page.

// src/components/Subscribe.js

import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Subscribe() {
  const [mobileNumber, setMobileNumber] = useState(""); // State to hold the mobile number
  const [isButtonDisabled, setIsButtonDisabled] = useState(true); // State to manage button disabled/enabled state
  const [error, setError] = useState(null); // State to manage error messages
  const [successMessage, setSuccessMessage] = useState(null); // State to manage success messages
  const navigate = useNavigate();

  // Handler to update mobile number and validate its length
  const handleMobileChange = (e) => {
    const value = e.target.value;

    // Only allow digits
    const validNumber = value.replace(/\D/g, "");
    setMobileNumber(validNumber);

    // Check if the number is exactly 12 digits
    if (validNumber.length === 12) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  };

  // Handler for the subscribe button click
  const handleSubscribe = async () => {
    try {
      // Prepare the API request
      const config = {
        method: "post",
        url: "https://ktapis.ktgamez.com/irancell/api/v1/users/subscribe",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          mobile: mobileNumber, // Use the state that holds the user's mobile number
        }),
      };

      // Make the API call to subscribe the user
      const response = await axios(config);

      // Check if the subscription was successful
      if (response.data && response.data.status === "Success") {
        setSuccessMessage("You've successfully subscribed. Kindly Login to continue.");
        setError(null); // Clear error message if any
      } else {
        setError(
          "Subscription failed. Please check your mobile number and try again."
        );
      }
    } catch (err) {
      console.error("Subscription Error:", err);
      setError("An error occurred while subscribing. Please try again later.");
    }
  };

  // Handler for the login button click
  const handleLogin = () => {
    navigate("/login"); // Redirect to login page
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title text-center">Subscribe</h2>
              <form>
                <div className="form-group mb-3">
                  <label htmlFor="mobileNumber">Mobile Number</label>
                  <input
                    type="text"
                    id="mobileNumber"
                    name="mobileNumber"
                    value={mobileNumber}
                    onChange={handleMobileChange}
                    placeholder="Enter 12 digit UAE number e.g: 981111111111"
                    maxLength={12} // Limit the input length to 12 digits
                    className="form-control"
                  />
                </div>
                <button
                  type="button"
                  className="btn btn-primary w-100 mb-3"
                  onClick={handleSubscribe}
                  disabled={isButtonDisabled} // Disable button unless input is valid
                >
                  Subscribe
                </button>
                {/* Add a Login button */}
                <button
                  type="button"
                  className="btn btn-secondary w-100"
                  onClick={handleLogin}
                >
                  Log in
                </button>
              </form>
              {/* Display success message */}
              {successMessage && (
                <div className="alert alert-success mt-3">{successMessage}</div>
              )}
              {/* Display error message */}
              {error && <div className="alert alert-danger mt-3">{error}</div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Subscribe;
