// src/components/Navbar.js

import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import logo from "../GiroGamez.png"; // Import the logo image
import "bootstrap/dist/css/bootstrap.min.css";
import { Dropdown } from "react-bootstrap";

function Navbar({ setSelectedGenre, points }) {

  const navigate = useNavigate();


  const genres = [
    "All",
    "Action",
    "Educational",
    "Arcade",
    "Sports & Racing",
    "Puzzle",
  ];



  // Handler for logging out
  const handleLogout = () => {
    // Clear the accessToken from localStorage
    localStorage.removeItem("accessToken");

    // Redirect to the login page
    navigate("/login");
  };

  // Handler for subscribing
  const handleSubscribe = (e) => {
    e.preventDefault(); // Prevent default behavior
    console.log("Subscribe button clicked"); // Add this for debugging
    navigate("/subscribe"); // Redirect to the Subscribe page
  };

  // Check if user is logged in by checking if accessToken exists
  const isLoggedIn = !!localStorage.getItem("accessToken");

  return (
    <nav className="navbar navbar-expand-lg navbar-light">
      <div className="container-fluid justify-content-between">
        <a
          className="navbar-brand"
          href="#"
          onClick={() => {
            setSelectedGenre("All");
            navigate("/");
          }}
        >
          <img src={logo} alt="Giro Gamez" height="40" />
        </a>

        {/* Sidebar toggle button only visible on mobile screens */}
        <button
          className="btn btn-light d-lg-none"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasWithGenres"
          aria-controls="offcanvasWithGenres"
        >
          Genres
        </button>

        {/* Genre navigation visible on large screens */}
        <div className="navbar-nav-wrapper d-flex flex-row justify-content-center d-none d-lg-block">
          <ul className="navbar-nav d-flex flex-row flex-wrap overflow-auto">
            {genres.map((genre, index) => (
              <li key={index} className="nav-item">
                <a
                  className="nav-link text-light"
                  onClick={() => setSelectedGenre(genre)}
                >
                  <b>{genre}</b>
                </a>
              </li>
            ))}
          </ul>
        </div>

        <div className="d-flex align-items-center">
          {/* Static points display */}
          <div className="me-3 text-light">
            <i className="bi bi-star-fill"></i> {points} points
          </div>

          {/* Subscribe button - Always visible */}
          <button className="btn btn-primary me-2" onClick={handleSubscribe}>
            Subscribe
          </button>

          {/* Logout button visible only if the user is logged in */}
          {isLoggedIn && (
            <Dropdown align="end">
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                <i className="bi bi-person-circle"></i> Profile
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
