import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

function GenreSidebar({ setSelectedGenre }) {
  const genres = [
    "All",
    "Action",
    "Educational",
    "Arcade",
    "Sports & Racing",
    "Puzzle",
  ];

  return (
    <div
      className="offcanvas offcanvas-start d-flex flex-column dark-sidebar"
      tabIndex="-1"
      id="offcanvasWithGenres"
      aria-labelledby="offcanvasWithGenresLabel"
    >
      <div className="offcanvas-header">
        <h5 className="offcanvas-title" id="offcanvasWithGenresLabel">
          Genres
        </h5>
        <button
          type="button"
          className="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div
        className="offcanvas-body"
        style={{ flexGrow: 1, overflowY: "auto" }}
      >
        <ul className="navbar-nav">
          {genres.map((genre, index) => (
            <li key={index} className="nav-item">
              <a
                className="nav-link"
                href="#"
                onClick={() => setSelectedGenre(genre)}
                data-bs-dismiss="offcanvas" // This closes the sidebar after clicking a genre
              >
                {genre}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default GenreSidebar;
